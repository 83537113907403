import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squat 4×8\\@75% 1RM`}</p>
    <p>{`Wt’d Shoulder Bridge 4×8`}</p>
    <p>{`then,`}</p>
    <p>{`Three 2:00 rounds of:`}</p>
    <p>{`20/15 Calorie Assault Bike`}</p>
    <p>{`Max Thrusters (95/65)`}</p>
    <p>{`1:00 rest between sets.`}</p>
    <p>{`Score = total `}{`#`}{` of thrusters.`}</p>
    <p><strong parentName="p">{`*`}{`There will be no 5:30pm class on New Years Eve.  All other classes
will remain the same. The gym will close after the 4:30pm class.  `}</strong></p>
    <p><strong parentName="p">{`*`}{`We will have our free New Years Day class at 10:30am so invite a
friend!  There will be no other classes or open gym this day.`}</strong></p>
    <p><strong parentName="p">{`*`}{`Saturday, January 5th, all members are invited to our get together
at 5:00pm at Foam Warriorz and then dinner afterward.  Please RSVP if
you can make it.`}</strong></p>
    <p><strong parentName="p">{`*`}{`CrossFit the Ville’s Barbell Club will be starting January 19th!  If
you’d like to improve your Clean & Jerk and Snatch these classes are for
you.  Classes will be offered Saturday’s and Sunday’s each weekend after
our CrossFit classes.  Email Daniel for more information.`}</strong></p>
    <p><strong parentName="p">{`*`}{`The Granite Games Throwdown in coming up January 12th.  You still
have time to register but we only have 5 spots left.  Get your teams
together and registered soon to reserve your spot! `}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://competitioncorner.net/events/1549?fbclid=IwAR0ST57-McRs4RyVzEL5D6sr_LV403-KCqTNNEKweUXuq32ASGMdLOHFY4s#.W_biri3Mz1w"
      }}>{`https://competitioncorner.net/events/1549?fbclid=IwAR0ST57-McRs4RyVzEL5D6sr_LV403-KCqTNNEKweUXuq32ASGMdLOHFY4s#.W_biri3Mz1w`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      